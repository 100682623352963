<template>
    <div class="hs2Com7">
        <div class="hs27Box">
            <!-- 标题 -->
            <div class="hs27Title">
                <p class="hs27Big">案例展示</p>
                <p class="hs27Small">CASE SHOWS</p>
            </div>
            <!-- 图片列表 -->
            <div class="hs27Imgs">
                <ul>
                    <!-- <li class="hs27Li">
                        <img src="http://iv.vu818.com/img/ksphone.png" class="ksphone" alt="" />
                        <a href="#">
                            <img src="http://iv.vu818.com/img/wfx_1.png" alt="" class="hs27Img" />
                            <div class="hs27Code">
                                <img class="hs27CImg" src="http://iv.vu818.com/img/wfx_1_code.png" alt="" />
                                <div class="hs27Text">早康枸杞</div>
                            </div></a
                        >
                    </li>
                    <li class="hs27Li">
                        <img src="http://iv.vu818.com/img/ksphone.png" class="ksphone" alt="" />
                        <a href="#">
                            <img src="http://iv.vu818.com/img/wfx_2.png" alt="" class="hs27Img" />
                            <div class="hs27Code">
                                <img class="hs27CImg" src="http://iv.vu818.com/img/wfx_1_code.png" alt="" />
                                <div class="hs27Text">集美优选</div>
                            </div></a
                        >
                    </li>
                    <li class="hs27Li">
                        <img src="http://iv.vu818.com/img/ksphone.png" class="ksphone" alt="" />
                        <a href="#">
                            <img src="http://iv.vu818.com/img/wfx_3.png" alt="" class="hs27Img" />
                            <div class="hs27Code">
                                <img class="hs27CImg" src="http://iv.vu818.com/img/wfx_1_code.png" alt="" />
                                <div class="hs27Text">威有</div>
                            </div></a
                        >
                    </li>
                    <li class="hs27Li">
                        <img src="http://iv.vu818.com/img/ksphone.png" class="ksphone" alt="" />
                        <a href="#">
                            <img src="http://iv.vu818.com/img/wfx_4.png" alt="" class="hs27Img" />
                            <div class="hs27Code">
                                <img class="hs27CImg" src="http://iv.vu818.com/img/wfx_1_code.png" alt="" />
                                <div class="hs27Text">大连盐化集团</div>
                            </div></a
                        >
                    </li> -->
                    <vy-phone v-for="el in list.slice(0, 4)" :key="el.id" :element="el" />
                </ul>
            </div>
            <button @click="$router.push('/case')">更多案例</button>
        </div>
    </div>
</template>

<script>
import VyPhone from "@/util/VyPhone.vue";

export default {
    name: "hs2Com7",
    components: { VyPhone },
    props: ["list"],
};
</script>

<style lang="less" scoped>
.hs2Com7 {
    min-width: 1423px;
    height: 740px;
    margin-top: 60px;
    .hs27Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 标题
        .hs27Title {
            width: 50%;
            height: 80px;
            text-align: center;
            margin: 0 auto;
            .hs27Big {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 40px;
                font-weight: 900;
            }
            .hs27Small {
                width: 100%;
                height: 30px;
                line-height: 30px;
                font-size: 20px;
            }
        }
        & > button {
            width: 127px;
            height: 40px;
            margin: 0 auto;
            font-size: 15px;
            line-height: 40px;
            text-align: center;
            border-radius: 25px;
            color: white;
            background-color: #7781f1;
            transition: 0.5s;
            border: none;
            cursor: pointer;
            margin-top: 20px;
        }
        // 图片列表
        .hs27Imgs {
            width: 100%;
            height: 530px;
            margin-top: 20px;

            ul {
                width: 100%;
                height: 100%;
                text-align: center;
                display: flex;
                justify-content: space-around;
                position: relative;
                .hs27Li {
                    margin-top: 20px;
                    position: relative;
                    // overflow: hidden;
                    & > a {
                        display: block;
                        overflow: hidden;
                        position: relative;
                        border-radius: 20px;
                    }
                    .ksphone {
                        width: 113%;
                        height: 97%;
                        position: absolute;
                        top: -11px;
                        left: -18px;
                    }
                    .hs27Code {
                        width: 250px;
                        height: 475px;
                        border-radius: 20px;
                        background-color: rgba(114, 107, 107, 0.8);
                        opacity: 0;
                        position: absolute;
                        top: -475px;
                        transition: 0.5s;
                        .hs27CImg {
                            margin-top: 150px;
                            border-radius: 10px;
                        }
                        .hs27Text {
                            width: 100%;
                            height: 50px;
                            line-height: 50px;
                            font-size: 25px;
                            font-weight: 900;
                            color: white;
                        }
                    }
                }
                .hs27Li:hover {
                    .hs27Code {
                        opacity: 1;
                        transform: translateY(475px);
                    }
                }
            }
        }
    }
}
</style>
