<template>
    <div class="hs2Com6">
        <img src="http://iv.vu818.com/img/hc3269.jpg" alt="" class="h26Bg" />
        <div class="hs26Box">
            <!-- 标题 -->
            <div class="hs26Title">
                <p class="hs26TBig">功能强大的 微分销商城系统</p>
                <p class="hs26TSmall">为企业搭建微分销商城系统，营销功能丰富，可个性化定制，提升品牌价值</p>
            </div>
            <!-- 顶部导航 -->
            <div class="hs26Tabs">
                <ul>
                    <li class="hs26TLi" :class="{ hs26Active: isActive === 1 }" @mousemove="showTheData(1)">店铺装修</li>
                    <li class="hs26TLi" :class="{ hs26Active: isActive === 2 }" @mousemove="showTheData(2)">平台运营</li>
                    <li class="hs26TLi" :class="{ hs26Active: isActive === 3 }" @mousemove="showTheData(3)">零售\批发\代理</li>
                    <li class="hs26TLi" :class="{ hs26Active: isActive === 4 }" @mousemove="showTheData(4)">智慧门店</li>
                    <li class="hs26TLi" :class="{ hs26Active: isActive === 5 }" @mousemove="showTheData(5)">视频卖货</li>
                    <li class="hs26TLi" :class="{ hs26Active: isActive === 6 }" @mousemove="showTheData(6)">会员体系</li>
                    <li class="hs26TLi" :class="{ hs26Active: isActive === 7 }" @mousemove="showTheData(7)">营销工具</li>
                    <li class="hs26TLi" :class="{ hs26Active: isActive === 8 }" @mousemove="showTheData(8)">大数据分析</li>
                </ul>
            </div>
            <!-- 内容 -->
            <div class="hs26Data">
                <div class="hs26DLi" v-show="show26Data === 1">
                    <!-- 左图 -->
                    <div class="hs26DImg">
                        <img src="http://iv.vu818.com/img/hc3261.png" alt="" />
                    </div>
                    <!-- 右文 -->
                    <div class="hs26Dt">
                        <p class="hs26Big">店铺打理更灵活、更便捷</p>
                        <p class="hs26Small">
                            可视化装修，组件随意拖拽，丰富的模板资源与个性化装修，满足 不同客户需求，不同管理角色，不同管理权限，权责更明确。
                        </p>
                        <dl>
                            <dt>个性化装修</dt>
                            <dt>专题页面设置</dt>
                            <dt>名片自定义</dt>
                            <dt>PC端装修</dt>
                            <dt>APP管理</dt>
                            <dt>功能名称自定义</dt>
                            <dt>系统设置</dt>
                            <dt>店铺设置</dt>
                            <dt>微信设置</dt>
                            <dt>物流管理</dt>
                            <dt>收款方式管理</dt>
                            <dt>管理权限</dt>
                        </dl>
                        <div class="hs26Btn1">
                            获取更多功能
                            <div class="h2Code">
                                <img :src="getWebData().wechat_visitor" alt="" />
                            </div>
                        </div>
                        <div class="hs26Btn">
                            免费报价
                            <div class="h2Code">
                                <img :src="getWebData().public_phone" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hs26DLi" v-show="show26Data === 2">
                    <!-- 左图 -->
                    <div class="hs26DImg">
                        <img src="http://iv.vu818.com/img/hc3262.png" alt="" />
                    </div>
                    <!-- 右文 -->
                    <div class="hs26Dt">
                        <p class="hs26Big">一站式微信分销系统+微商城搭建，支持多种运营模式</p>
                        <p class="hs26Small">
                            帮助企业快速搭建“公众号+小程序”自营商城、多供应商入驻商城平台，
                            有效帮助传统企业转型新零售、电商企业升级新商业模式、微商清货、门店O2O......
                        </p>
                        <dl>
                            <dt>商城自营</dt>
                            <dt>供应商入驻</dt>
                            <dt>招商奖励</dt>
                            <dt>供应商独立门铺</dt>
                            <dt>供应商审核</dt>
                            <dt>供应商发货</dt>
                            <dt>供应商商品管理</dt>
                            <dt>供应商门店</dt>
                            <dt>供应商标签</dt>
                            <dt>数据导出</dt>
                            <dt>订单数据统计</dt>
                            <dt>财务报表分析</dt>
                        </dl>
                        <div class="hs26Btn1">
                            获取更多功能
                            <div class="h2Code">
                                <img :src="getWebData().wechat_visitor" alt="" />
                            </div>
                        </div>
                        <div class="hs26Btn">
                            免费报价
                            <div class="h2Code">
                                <img :src="getWebData().public_phone" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hs26DLi" v-show="show26Data === 3">
                    <!-- 左图 -->
                    <div class="hs26DImg">
                        <img src="http://iv.vu818.com/img/hc3263.png" alt="" />
                    </div>
                    <!-- 右文 -->
                    <div class="hs26Dt">
                        <p class="hs26Big">可分销零售，可批发代理，多业务场景应用</p>
                        <p class="hs26Small">
                            快速搭建微分销系统，代理商可收获一整条线的佣金，鼓励订货商、代理商带团队、 抓业绩，有利于商家快速扩大规模
                        </p>
                        <dl>
                            <dt>分销返佣</dt>
                            <dt>智能佣金结算</dt>
                            <dt>智能关系绑定</dt>
                            <dt>全网渠道营销</dt>
                            <dt>代理商管理</dt>
                            <dt>订货商管理</dt>
                            <dt>团队业绩奖励</dt>
                            <dt>订货级差</dt>
                            <dt>推荐奖励</dt>
                            <dt>虚拟仓库</dt>
                            <dt>代理商区域权限</dt>
                            <dt>分销商自动升级</dt>
                        </dl>
                        <div class="hs26Btn1">
                            获取更多功能
                            <div class="h2Code">
                                <img :src="getWebData().wechat_visitor" alt="" />
                            </div>
                        </div>
                        <div class="hs26Btn">
                            免费报价
                            <div class="h2Code">
                                <img :src="getWebData().public_phone" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hs26DLi" v-show="show26Data === 4">
                    <!-- 左图 -->
                    <div class="hs26DImg">
                        <img src="http://iv.vu818.com/img/hc3264.png" alt="" />
                    </div>
                    <!-- 右文 -->
                    <div class="hs26Dt">
                        <p class="hs26Big">智慧门店O2O，线下门店转型利器</p>
                        <p class="hs26Small">O2O智慧门店分销系统帮助门店整合线上线下资源，相互导流，利用社交电商获取 流量。</p>
                        <dl>
                            <dt>门店独立后台</dt>
                            <dt>门店奖励</dt>
                            <dt>门店自提/配送</dt>
                            <dt>门店扫码支付</dt>
                            <dt>门店分销佣金</dt>
                            <dt>门店库存</dt>
                            <dt>门店配送定位</dt>
                            <dt>门店等级</dt>
                            <dt>门店预约</dt>
                            <dt>门店分类</dt>
                            <dt>门店员工管理</dt>
                            <dt>员工等级</dt>
                        </dl>
                        <div class="hs26Btn1">
                            获取更多功能
                            <div class="h2Code">
                                <img :src="getWebData().wechat_visitor" alt="" />
                            </div>
                        </div>
                        <div class="hs26Btn">
                            免费报价
                            <div class="h2Code">
                                <img :src="getWebData().public_phone" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hs26DLi" v-show="show26Data === 5">
                    <!-- 左图 -->
                    <div class="hs26DImg">
                        <img src="http://iv.vu818.com/img/hc3265.png" alt="" />
                    </div>
                    <!-- 右文 -->
                    <div class="hs26Dt">
                        <p class="hs26Big">公众号+小程序+APP，全渠道直播卖货</p>
                        <p class="hs26Small">
                            帮助企业快速搭建“公众号+小程序”分销卖货的直播系统，强力激活粉丝活跃度，一键分享直播间，
                            边播边卖、裂变拉新、快速成交、帮助企业提升销量和用户沉淀
                        </p>
                        <dl>
                            <dt>直播+短视频</dt>
                            <dt>分销直播</dt>
                            <dt>边看边买</dt>
                            <dt>商品推荐</dt>
                            <dt>主播实时互动</dt>
                            <dt>APP管理</dt>
                            <dt>团队直播</dt>
                            <dt>直播预告</dt>
                            <dt>弹幕点赞</dt>
                            <dt>高级美颜</dt>
                            <dt>支持录屏</dt>
                            <dt>免注册一键直播</dt>
                        </dl>
                        <div class="hs26Btn1">
                            获取更多功能
                            <div class="h2Code">
                                <img :src="getWebData().wechat_visitor" alt="" />
                            </div>
                        </div>
                        <div class="hs26Btn">
                            免费报价
                            <div class="h2Code">
                                <img :src="getWebData().public_phone" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hs26DLi" v-show="show26Data === 6">
                    <!-- 左图 -->
                    <div class="hs26DImg">
                        <img src="http://iv.vu818.com/img/hc3266.png" alt="" />
                    </div>
                    <!-- 右文 -->
                    <div class="hs26Dt">
                        <p class="hs26Big">强大的会员管理体系，增强会员粘性</p>
                        <p class="hs26Small">成熟的会员体系，完成用户沉淀，会员等级制度结合大数据精准推送</p>
                        <dl>
                            <dt>会员管理</dt>
                            <dt>会员等级</dt>
                            <dt>会员特权</dt>
                            <dt>会员分组</dt>
                            <dt>会员积分</dt>
                            <dt>会员卡</dt>
                            <dt>会员充值</dt>
                            <dt>会员积分</dt>
                            <dt>会员升级</dt>
                            <dt>会员营销</dt>
                            <dt>会员转赠积分</dt>
                            <dt>会员数据画像</dt>
                        </dl>
                        <div class="hs26Btn1">
                            获取更多功能
                            <div class="h2Code">
                                <img :src="getWebData().wechat_visitor" alt="" />
                            </div>
                        </div>
                        <div class="hs26Btn">
                            免费报价
                            <div class="h2Code">
                                <img :src="getWebData().public_phone" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hs26DLi" v-show="show26Data === 7">
                    <!-- 左图 -->
                    <div class="hs26DImg">
                        <img src="http://iv.vu818.com/img/hc3267.png" alt="" />
                    </div>
                    <!-- 右文 -->
                    <div class="hs26Dt">
                        <p class="hs26Big">丰富的营销活动插件</p>
                        <p class="hs26Small">砍价、秒杀、拼团等各类主流营销工具，花样玩法，助力商家深挖用户价值</p>
                        <dl>
                            <dt>秒杀</dt>
                            <dt>拼团</dt>
                            <dt>限时购</dt>
                            <dt>满额包邮</dt>
                            <dt>满减优惠</dt>
                            <dt>打包一口价</dt>
                            <dt>周期购</dt>
                            <dt>首单立减</dt>
                            <dt>游戏营销</dt>
                            <dt>优惠卷</dt>
                            <dt>充值营销</dt>
                            <dt>积分商城</dt>
                        </dl>
                        <div class="hs26Btn1">
                            获取更多功能
                            <div class="h2Code">
                                <img :src="getWebData().wechat_visitor" alt="" />
                            </div>
                        </div>
                        <div class="hs26Btn">
                            免费报价
                            <div class="h2Code">
                                <img :src="getWebData().public_phone" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hs26DLi" v-show="show26Data === 8">
                    <!-- 左图 -->
                    <div class="hs26DImg">
                        <img src="http://iv.vu818.com/img/hc3268.png" alt="" />
                    </div>
                    <!-- 右文 -->
                    <div class="hs26Dt">
                        <p class="hs26Big">大数据精准营销</p>
                        <p class="hs26Small">大数据驱动商城运营，通过缝合整合多维度数据，构建分析模型，实现营销精准化， 全面提升销售业绩</p>
                        <dl>
                            <dt>商城运营情况</dt>
                            <dt>订单数据</dt>
                            <dt>商品数据</dt>
                            <dt>财务数据</dt>
                            <dt>会员数据</dt>
                            <dt>代理商数据</dt>
                            <dt>营销活动数据</dt>
                            <dt>分销数据</dt>
                            <dt>新增留存数据</dt>
                            <dt>消费潜力数据</dt>
                            <dt>业务线排名数据</dt>
                            <dt>实时数据大屏</dt>
                        </dl>
                        <div class="hs26Btn1">
                            获取更多功能
                            <div class="h2Code">
                                <img :src="getWebData().wechat_visitor" alt="" />
                            </div>
                        </div>
                        <div class="hs26Btn">
                            免费报价
                            <div class="h2Code">
                                <img :src="getWebData().public_phone" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'hs2Com6',
    inject: ['getWebData'],
    data() {
        return {
            show26Data: 1,
            isActive: 1,
        };
    },
    methods: {
        showTheData(i) {
            this.show26Data = i;
            this.isActive = i;
        },
    },
};
</script>

<style lang="less" scoped>
.hs2Com6 {
    min-width: 1423px;
    height: 900px;
    margin-top: 50px;
    position: relative;
    .h26Bg {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
    }
    .hs26Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        // 标题
        .hs26Title {
            width: 70%;
            height: 100px;
            text-align: center;
            line-height: 50px;
            margin: 0 auto;
            margin-top: 65px;
            color: white;
            .hs26TBig {
                width: 100%;
                height: 50px;
                font-size: 35px;
                font-weight: 900;
            }
            .hs26TSmall {
                width: 100%;
                height: 50px;
                font-size: 25px;
            }
        }
        // 导航
        .hs26Tabs {
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            color: white;
            margin-top: 30px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                .hs26TLi {
                    width: 150px;
                    height: 50px;
                    font-weight: 900;
                    cursor: pointer;
                }
            }
            .hs26Active {
                border-bottom: 3px solid #05ddc8;
                box-sizing: border-box;
                color: #05ddc8;
            }
        }
        // 内容
        .hs26Data {
            width: 100%;
            height: 600px;
            margin-top: 10px;
            .hs26DLi {
                width: 100%;
                height: 100%;
                transition: 0.5s;
                animation-name: slideRight;
                animation-duration: 1s;
                animation-timing-function: ease-in-out;
                .hs26DImg {
                    width: 40%;
                    height: 100%;
                    float: left;
                    img {
                        padding-top: 80px;
                        padding-left: 30px;
                    }
                }
                .hs26Dt {
                    width: 50%;
                    height: 100%;
                    float: right;
                    margin-top: 80px;
                    color: white;
                    .hs26Big {
                        width: 100%;
                        height: 50px;
                        line-height: 50px;
                        font-size: 25px;
                        font-weight: 900;
                    }
                    .hs26Small {
                        width: 100%;
                        height: 50px;
                        line-height: 25px;
                        font-size: 16px;
                        text-align: left;
                    }
                }
                dl {
                    width: 100%;
                    height: 200px;
                    margin-top: 20px;
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;

                    text-align: center;
                    line-height: 50px;
                    color: white;
                    dt {
                        width: 23%;
                        height: 50px;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        border-radius: 10px;
                        cursor: pointer;
                        transition: 0.5s;
                    }
                    dt:hover {
                        transform: translateY(-10px);
                    }
                }
                .hs26Btn,
                .hs26Btn1 {
                    width: 250px;
                    height: 60px;
                    line-height: 60px;
                    margin-top: 50px;
                    text-align: center;
                    float: left;
                    margin-left: 50px;
                    border-radius: 30px;
                    font-size: 25px;
                    font-weight: 900;
                    color: #7781f1;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    background-color: #fff;
                    cursor: pointer;
                    position: relative;
                    transition: 0.5s;
                    .h2Code {
                        width: 100px;
                        height: 100px;
                        position: absolute;
                        top: -110px;
                        left: 75px;
                        border-radius: 10px;
                        overflow: hidden;
                        opacity: 0;
                        transition: 0.5s;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .hs26Btn:hover,
                .hs26Btn1:hover {
                    transform: translateY(-10px);
                    .h2Code {
                        opacity: 1;
                    }
                }
                .hs26Btn1 {
                    background-color: #05ddc8;
                    color: white;
                }
            }
        }
        @keyframes slideLeft {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
            0% {
                transform: translateX(150%);
            }
            50% {
                transform: translateX(-8%);
            }
            65% {
                transform: translateX(4%);
            }
            80% {
                transform: translateX(-4%);
            }
            95% {
                transform: translateX(2%);
            }
            100% {
                transform: translateX(0%);
            }
        }
    }
}
</style>
