<template>
    <div class="hs2Com3">
        <div class="hs23Box">
            <!-- 标题 -->
            <div class="hs23Title">
                <p class="hs23Big">"微分销系统"下的线上平台搭建</p>
                <p class="hs23Small">—————— 商城+分销 ——————</p>
            </div>
            <!-- 图文 -->
            <div class="hs23ImgText">
                <!-- 左图 -->
                <div class="hs23Left">
                    <p class="hs23Top">搭建</p>
                    <img src="http://iv.vu818.com/img/hc3231.png" alt="" class="hs23Img" />
                    <div class="hs23List">
                        <p>30秒帮您快速搭建线上商城</p>
                        <p>多样化的店铺打造独居特色的视觉效果</p>
                        <p>货品批量载入，快速铺货，快速收益</p>
                    </div>
                </div>
                <!-- 右图 -->
                <div class="hs23Right">
                    <p class="hs23Top">优势</p>
                    <img src="http://iv.vu818.com/img/hc3232.png" alt="" class="hs23Img" />
                    <div class="hs23List">
                        <p>零售+分销，双轮驱动，自带销售力</p>
                        <p>多种营销组合工具帮助您轻松卖货</p>
                        <p>丰富的分佣机制激励分销裂变</p>
                        <p>转发分销，一键分享，快速引流</p>
                        <p>系统机制完善，方便管理与操作</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Hs2Com3',
};
</script>

<style lang="less" scoped>
.hs2Com3 {
    width: 1423px;
    height: 600px;
    margin: 0 auto;
    background: url('http://iv.vu818.com/img/wfx_39.jpg') center no-repeat;
    .hs23Box {
        width: 90%;
        height: 100%;
        margin: 0 auto;
        margin-top: 100px;
        overflow: hidden;
        // 标题
        .hs23Title {
            width: 60%;
            height: 80px;
            margin: 0 auto;
            margin-top: 40px;
            text-align: center;
            color: white;
            .hs23Big {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 40px;
                font-weight: 900;
            }
            .hs23Small {
                width: 100%;
                height: 30px;
                line-height: 30px;
                font-size: 25px;
            }
        }
        // 图文
        .hs23ImgText {
            width: 100%;
            height: 400px;
            margin-top: 50px;
            .hs23Left {
                background: url('http://iv.vu818.com/img/wfx_37.png') center no-repeat;
            }
            .hs23Right {
                background: url('http://iv.vu818.com/img/wfx_38.png') center no-repeat;
            }
            .hs23Left,
            .hs23Right {
                width: 50%;
                height: 100%;
                float: left;
                position: relative;
                transition: 0.5s;
                .hs23Top {
                    position: absolute;
                    top: 50px;
                    left: 290px;
                    font-size: 30px;
                    color: white;
                }
                .hs23Img {
                    position: absolute;
                    top: 150px;
                    left: 50px;
                }
                .hs23List {
                    position: absolute;
                    top: 150px;
                    right: 100px;
                    p {
                        margin-top: 20px;
                    }
                }
            }
            .hs23Right {
                .hs23List {
                    position: absolute;
                    top: 100px;
                    right: 100px;
                }
            }
            .hs23Left:hover {
                transform: translateY(-10px);
            }
            .hs23Right:hover {
                transform: translateY(-10px);
            }
        }
    }
}
</style>
